import React from 'react';
import PropTypes from 'prop-types';
import { StyledMaps } from './StyledMaps';

const Maps = ({ className }) => (
  <StyledMaps
    className={className}
    loading='lazy'
    src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1494.2407490846663!2d4.9502012!3d52.5020514!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60776b0923541%3A0x6d2b89ba2154b198!2sM.%20Pals%20Stukadoors!5e1!3m2!1snl!2snl!4v1578297914323!5m2!1snl!2snl'
    frameborder='0'
  />
);

Maps.propTypes = {
  className: PropTypes.string,
};

export default Maps;
