import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Grid, Container, Button } from '@material-ui/core';

import Layout from '../components/Layout';
import { StyledRibbon } from '../components/Ribbon/StyledRibbon';
import Maps from '../components/Maps';
import SEO from '../components/SEO';

export const query = graphql`
  query {
    mobileImage1: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage1: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Contact = ({ path, location, data }) => {
  const seo = {
    title: 'Contact -M.Pals Stukadoors',
    description:
      'Vragen over mogelijkheden of professioneel advies? Neem gerust contact op met M.Pals Stukadoors',
    pathname: path,
    location,
  };

  const sources1 = [
    data.mobileImage1.childImageSharp.fluid,
    {
      ...data.desktopImage1.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  return (
    <>
      <Layout>
        <SEO
          title={seo.title}
          description={seo.description}
          location={location}
        />

        <StyledRibbon>
          <div className='heading'>
            <Img
              loading='auto'
              className='heading__image'
              fluid={sources1}
              alt='M.Pals Stukadoors'
            />
            <div className='heading__text'>
              <div className='heading__container center-xs ml-4x mr-4x mt-8x mt-sm-0'>
                <h1>
                  Contact <i className='material-icons'>phone</i>
                </h1>
                <p className='mb-2x'>
                  Bel anders gewoon even, dan bespreken we samen de
                  mogelijkheden
                </p>
                <h3>06-29575549</h3>
                <div className='hidden-sm hidden-md hidden-lg'>
                  <a href='tel:0629575549'>
                    <Button
                      role='button'
                      variant='contained'
                      className='btn btn--secondary heading__button mt-4x '
                    >
                      <i className='material-icons mr-1x'>phone</i>Krijg advies
                      op maat
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </StyledRibbon>
        <Container fixed>
          <Grid container className='mb-10x mt-10x'>
            <Grid item xs={12} md={6} className='pr-md-4x'>
              <h2>Wie zijn M.Pals Stukadoors?</h2>
              <p>
                M.Pals is actief als stukadoor in de omgeving van Purmerend,
                Waterland, Amsterdam, Zaanstad, en Hoorn. Met onze ruime
                ervaring is M.Pals Stukadoors het juiste adres voor een
                klantgerichte service, uitstekende adviezen en zeer vakkundig
                stukwerk.
              </p>
              <p>
                Bij M.Pals Stukadoor staat kwaliteit centraal. Met onze zeer
                ervaren werknemers streven wij ernaar om de beste kwaliteit te
                garanderen.
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <Maps className='maps mt-8x mt-sm-0' />
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

Contact.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Contact;
